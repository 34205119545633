// Table of contents
@import "tableofcontent";

/*

[ 01. Imported CSS ]

*/
@import "common/imported";

// Base
@import "common/variables";
@import "common/mixins";
@import "common/rfs";

/*

[ 02. Typography CSS ]

*/
@import "common/typography";

/*

[ 03. Common CSS ]

*/
@import "hope/common";

/*

[ 04. Header CSS ]

*/

@import "header/header";
@import "header/offcanvas-toggler";
@import "header/offcanvas";

// Banner Styles
@import "hope/banner";

// Components

/*

[ 05. Hero slider CSS ]

*/

/*

[ 06 About CSS ]

*/

@import "hope/about";

/*

[ 07 Service CSS ]

*/

@import "hope/service";

/*

[ 08 Team CSS ]

*/

@import "hope/team";

@import "hope/gallery";
@import "hope/pricing";
@import "hope/testimonial";

/*

[ 09 Brand Logo CSS ]

*/

@import "hope/brand-logo";
@import "hope/feature";
@import "hope/divider";
@import "hope/icon-box";

/*

[ 10 Form CSS ]

*/

@import "hope/form";
@import "hope/department";
@import "hope/case";

// Pages
@import "pages/home-default";

// Blog

/*

[ 11 Blog CSS ]

*/

@import "hope/blog";

// Contact

/*

[ 12 Contact CSS ]

*/

@import "hope/contact";

/*

[ 13 Footer CSS ]

*/

// Footer
@import "footer/footer";

.about-page {
  h1 {
    margin: 0;
    font-size: 28px;
  }

  p {
    font-size: 16px;
  }

  main {
    padding: 20px;
  }

  section {
    margin-bottom: 40px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
  }

  section:last-child {
    border-bottom: none;
  }

  .director-image {
    max-width: 300px;
    max-height: 300px;
    height: 100%;
    width: 100%;
    border-radius: 3px;
    margin-right: 20px;
    float: left;
    object-fit: contain;
  }

  .director-content {
    flex-grow: 1;
  }
}
