:root {
  --primary-color: #063189;
  --secondary-color: #24a9e1;
  --secondary-hover-color: #1a88b8;
  --primary-hover-color: #041f55;
  --gray: #848484;
}

body > iframe[style*="2147483647"] {
  display: none !important;
}

.page-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  height: 200px;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-content {
  min-height: calc(100vh - 307px);
}

.header-logo img,
.login_form img {
  height: 80px !important;
  background-color: white !important;
  border-radius: 50%;
  object-fit: contain;
}

.bg-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
}

.team-member img {
  min-height: 300px;
  max-height: 300px;
  height: 300px !important;
  object-fit: cover;
  object-position: top;
}

.lab-report-btn {
  background-color: var(--primary-hover-color);
}

.login_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
}

.login_form {
  display: flex;
  flex-direction: column;
  width: min(400px, 100%);
  border-radius: 5px;
  padding: 20px;
  margin-top: -40px;
  font-size: 18px;
  background-color: white;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}

.login_header {
  color: var(--primary-color);
  margin-bottom: 30px;
  text-align: center;
}

.login_form > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
}

.login_form label {
  font-size: 16px;
  font-weight: 500;
}

.login_form input#checkbox {
  accent-color: var(--primary-color);
}

.login_input {
  height: 45px;
  border-radius: 3px;
  border: 1px solid lightgray;
  padding: 0px 5px;
}

.login_input:focus {
  outline: none;
  border: 1px solid var(--primary-color);
}

.login_btn {
  height: 45px;
  background-color: var(--primary-color);
  border: none;
  color: white;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  opacity: 1 !important;
}

.login_btn:hover {
  background-color: var(--primary-color);
  opacity: 0.8 !important;
}

.login_btn:disabled {
  opacity: 0.5 !important;
}

.continue-btn {
  background-color: var(--secondary-color);
}

.continue-btn:hover {
  background-color: var(--secondary-hover-color);
}

.show_password {
  display: flex;
  flex-direction: row !important;
  align-items: center;
}

.show_password > input {
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.show_password > label {
  cursor: pointer;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--primary-color);
  border-color: var(--primary-color) transparent var(--primary-color)
    transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.section-header {
  font-size: 20px;
}

.clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.custom-btn:disabled {
  background-color: #848484 !important;
}

.news-title {
  color: #272727;
}

.news-desc {
  color: #272727;
  font-size: 16px;
}

.news-card {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 100%;
}
.news-card-header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.news-card-body {
  padding: 20px;
  min-height: 155px;
}

.news-date {
  font-size: 12px;
  text-align: left;
}

.news-card-body h4 {
  text-align: left;
}

.news-card-body p {
  text-align: left;
  font-size: 13px;
  margin: 0 0 10px;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 992px) {
  .header-logo img {
    height: 50px !important;
  }
}

.obj-list li {
  list-style: none;
  color: black;
  font-size: 16px;
  padding: 5px 10px;
  margin-bottom: 20px;
  border-left: 5px solid var(--primary-color);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 0px;
}
