/*
-----------------------------------------------------------------------
  Common CSS
-----------------------------------------------------------------------
*/

.container {
    @media #{$xl-device} {
        max-width: 1170px;
    }
}
.bg-white {
    background-color: #ffffff;
}
.bg-gray {
    background-color: #f3f3f3;
}
.section-title {
    margin-bottom: 80px;
    @media #{$md-device} {
        margin-bottom: 70px;
    }
    @media #{$sm-device} {
        margin-bottom: 60px;
    }
    @media #{$xs-device} {
        margin-bottom: 50px;
    }
    p {
        color: #999999;
        font-size: 14px;
        font-weight: $font-weight-normal;
        letter-spacing: 0.3px;
        margin-bottom: 15px;
        text-transform: uppercase;
        @media #{$sm-device} {
            font-size: 12px;
            margin-bottom: 12px;
        }
    }
    .title {
        font-size: 48px;
        font-weight: $font-weight-light;
        letter-spacing: -1.377px;
        line-height: 1.1;
        margin-bottom: 25px;
        @media #{$lg-device} {
            font-size: 40px;
        }
        @media #{$sm-device} {
            font-size: 30px;
        }
        @media #{$xs-device} {
            font-size: 25px;
        }
        span {
            color: var(--primary-color);
            font-weight: $font-weight-bold;
        }
    }
    .desc {
        margin-bottom: 55px;
        p {
            font-size: 14px;
            line-height: 30px;
            margin-bottom: 0;
            @media #{$sm-device} {
                br {
                    display: none;
                }
            }
        }
    }
    .separator-line {
        border-bottom: 1px solid $theme-color;
        display: block;
        margin: 0 auto 40px;
        width: 100px;
    }
    .list-style {
        li {
            color: $body-color;
            font-size: 14px;
            margin-bottom: 16px;
            padding-left: 29px;
            position: relative;
            i {
                color: $theme-color;
                font-size: 14px;
                left: 0;
                position: absolute;
                top: 5px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.section-light {
        h5 {
            color: $white;
        }
        .title {
            color: $white;
        }
        .desc {
            p {
                color: $white;
            }
        }
        .separator-line {
            border-bottom-color: $white !important;
            &.separator-double {
                &:before {
                    border-bottom-color: $white !important;
                }
            }
        }
    }
}

.bg-img {
    background: no-repeat center center;
    background-size: cover;
    &.bg-img-top {
        background-position: top center;
    }
}

.site-wrapper-reveal {
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.niceselect {
    float: inherit;
    .list {
        border-radius: 0;
        width: 100%;
    }
}

.line-hr {
    border-bottom: 1px solid #e2e2e2;
}

.lh-1 {
    line-height: 1;
}

.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.content-items-center {
    align-items: center;
    display: flex;
}

.justify-items-center {
    display: flex;
    justify-content: center;
}

.min-height-auto {
    min-height: auto !important;
}

.height-auto {
    height: auto !important;
}

.form-message.alert {
    margin-top: 10px;
}

.row-gutter-0 {
    [class*="col-"] {
        margin: 0;
        padding: 0;
    }
}

@media #{$md-device} {
    .md-text-center {
        text-align: center;
    }
}

@media #{$sm-device} {
    .sm-text-center {
        text-align: center;
    }
}

@media #{$xs-device} {
    .xs-w-100 {
        width: 100% !important;
    }
}

/*-------- Button Style Css --------*/

.btn-theme {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    border-radius: 0;
    color: $white;
    display: inline-block;
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 30px;
    min-height: 50px;
    padding: 10px 30px;
    position: relative;
    text-transform: uppercase;
    @include transition(0.3s);

    .icon {
        font-size: 14px;
        padding-left: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
    }

    &:hover,
    &:focus {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        color: $white;
    }

    &.btn-black {
        background-color: $black;
        border-color: $black;

        &:hover {
            background-color: $theme-color;
            border-color: $theme-color;
            color: $white;
        }
    }

    &.btn-white {
        background-color: $white;
        border-color: $white;
        color: $black-0e;

        &:hover {
            background-color: $black;
            border-color: $black;
            color: $white;
        }

        &.btn-border {
            background-color: transparent;
            border: 1px solid rgba($white, 0.5);
            color: $white;
            line-height: 29px;
            &:hover {
                background-color: $theme-color;
                border-color: $theme-color;
                color: $white;
            }
        }
    }

    &.btn-gray {
        background-color: $white-f4;
        border-color: $white-f4;
        color: $black-0e;

        &:hover {
            background-color: $theme-color;
            border-color: $theme-color;
            color: $white;
        }
    }

    &.btn-border {
        background-color: transparent;
        border: 1px solid $theme-color;
        color: $theme-color;
    }

    &.btn-round {
        border-radius: 25px;
        &.btn-slide {
            &:before {
                border-radius: 25px;
            }
        }
    }

    &.btn-lg {
        letter-spacing: 0.131px;
        padding: 14px 48px;
    }

    &.btn-slide {
        position: relative;
        z-index: 1;
        &:before {
            background-color: $theme-color3;
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 0;
            z-index: -1;
            @include transition(0.3s);
        }
        &:hover {
            &:before {
                width: 100%;
            }
        }
    }

    &.btn-size-md {
        height: 50px;
        min-width: 160px;
        text-align: center;
    }

    &.btn-size-lg {
        height: 50px;
        min-width: 180px;
        text-align: center;
    }

    &.btn-size-xl {
        height: 50px;
        min-width: 220px;
        text-align: center;
    }

    &.btn-size-xxl {
        height: 52px;
        min-width: 270px;
        text-align: center;
    }
}

.btn-link {
    color: $black;
    display: inline-block;
    font-weight: $font-weight-normal;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    @include transition(0.3s);
    &:hover {
        color: $theme-color;
        text-decoration: none;
    }
    span {
        & + .icon {
            color: $theme-color;
            font-size: 14px;
            padding-left: 17px;
            &:before {
                font-weight: 900;
            }
        }
    }
    &:focus {
        text-decoration: none;
    }
    &.btn-line {
        &:after {
            background-color: $theme-color;
            bottom: 0;
            content: "";
            height: 1px;
            right: 0;
            position: absolute;
            width: 0;
            @include transition(0.3s);
        }
        &:hover {
            color: $black;
            &:after {
                left: 0;
                width: 100%;
            }
        }
    }
    &.btn-dark {
        background-color: transparent;
        border: none;
        color: $black-0e;
        &:after {
            background-color: $black-0e;
            left: 0;
            width: 100%;
        }
        &:hover {
            color: $theme-color;
            &:hover {
                &:before {
                    background-color: $theme-color;
                }
                &:after {
                    background-color: $theme-color;
                }
            }
        }
    }
}

/*-------- Owl Carousel Style Css --------*/

.owl-carousel {
    &.owl-theme {
        .owl-nav {
            margin: 0;
            [class*="owl-"] {
                background-color: transparent;
                font-size: 24px;
                font-weight: $font-weight-black;
                margin: 0;
                @include transition(0.4s);
                &:hover {
                    color: $theme-color;
                }
            }
            .owl-prev {
                margin-right: 10px;
            }
            .owl-next {
                margin-left: 10px;
            }
        }
        &.owl-nav2 {
            .owl-nav {
                left: 0;
                position: absolute;
                right: 0;
                top: 50%;
                [class*="owl-"] {
                    position: absolute;
                    &.owl-prev {
                        left: 0;
                    }
                    &.owl-next {
                        right: 0;
                    }
                }
            }
        }
        .owl-dots {
            margin-top: 55px;
            .owl-dot {
                span {
                    background-color: #808080;
                    border: 1px solid #808080;
                    height: 10px;
                    margin: 5px 12px;
                    width: 10px;
                    @include transition(0.4s);
                }
                &.active,
                &:hover {
                    span {
                        background-color: $theme-color;
                        border-color: $theme-color;
                    }
                }
            }
        }
    }
    &.owl-dots-none {
        .owl-dots {
            display: none;
        }
    }
}

/*-------- Page Title Style Css --------*/

.page-title-area {
    .page-title-content {
        letter-spacing: 0;
        padding: 60px 0;
        .title {
            color: $black;
            font-size: 48px;
            font-weight: $font-weight-light;
            letter-spacing: -1.377px;
            line-height: 1.1;
            @media #{$lg-device} {
                font-size: 36px;
            }
            @media #{$sm-device} {
                font-size: 30px;
            }
            span {
                color: $theme-color;
                font-weight: $font-weight-bold;
            }
        }
        .bread-crumbs {
            color: $black-888;
            font-size: 15px;
            margin-bottom: 2px;
            a {
                color: $black-888;
                &:hover {
                    color: $theme-color;
                }
            }
            span {
                &.active {
                    color: $theme-color5;
                }
            }
            .breadcrumb-sep {
                padding: 0 8.7px;
            }
            &.bread-crumbs-style2 {
                font-size: 14px;
                margin-bottom: 23px;
                margin-top: 44px;
                text-transform: uppercase;
                @media #{$sm-device} {
                    margin-top: 22px;
                }
            }
        }
        &.text-light {
            .title {
                color: $white;
            }
            .bread-crumbs {
                color: $white;
                a {
                    color: $white;
                }
            }
        }
        &.content-style2 {
            padding: 200px 0px;

            @media #{$md-device} {
                padding: 235px 0 100px;
            }
            @media #{$sm-device} {
                padding: 195px 0 65px;
            }
            .title {
                color: $white;
                font-family: $font-current-theme1;
                font-size: 90px;
                font-weight: $font-weight-extra-bold;
                letter-spacing: -2.04px;
                line-height: 100px;
                margin-bottom: 10px;
                @media #{$lg-device} {
                    font-size: 64px;
                    line-height: 78px;
                }
                @media #{$sm-device} {
                    font-size: 36px;
                    line-height: 1.1;
                }
                span {
                    color: $white;
                    font-weight: $font-weight-light;
                }
            }
            p {
                color: $white;
                font-size: 18px;
                line-height: 1;
                letter-spacing: 0.411px;
                margin-bottom: 34px;
                text-transform: uppercase;
                @media #{$lg-device} {
                    margin-bottom: 25px;
                }
                @media #{$md-device} {
                    margin-bottom: 20px;
                }
                @media #{$sm-device} {
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }
        }
        &.content-style3 {
            padding: 60px 0px;
            @media #{$lg-device} {
                padding: 50px 0px;
            }
            @media #{$sm-device} {
                padding: 30px 0px;
            }
        }
        &.content-style4 {
            padding: 50px 0px 6px;
            @media #{$lg-device} {
                padding: 50px 0px 6px;
            }
        }
        &.content-style5 {
            padding: 245px 0px;

            @media #{$lg-device} {
                padding: 200px 0px;
            }
            @media #{$md-device} {
                padding: 150px 0px;
            }
            @media #{$sm-device} {
                padding: 100px 0px;
            }
            .title {
                color: $white;
                font-family: $font-current-theme1;
                font-size: 90px;
                font-weight: $font-weight-extra-bold;
                letter-spacing: -2.04px;
                line-height: 100px;
                margin-bottom: 10px;
                @media #{$lg-device} {
                    font-size: 64px;
                    line-height: 78px;
                }
                @media #{$sm-device} {
                    font-size: 36px;
                    line-height: 1.1;
                }
                span {
                    color: $white;
                    font-weight: $font-weight-light;
                }
            }
            p {
                color: $white;
                font-size: 18px;
                line-height: 1;
                letter-spacing: 0.411px;
                margin-bottom: 34px;
                text-transform: uppercase;
                @media #{$lg-device} {
                    margin-bottom: 25px;
                }
                @media #{$md-device} {
                    margin-bottom: 20px;
                }
                @media #{$sm-device} {
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.bread-crumbs {
    color: $body-color;
    font-size: 16px;
    a {
        color: $body-color;
        &:hover {
            color: $theme-color;
        }
    }
}

/*-------- Pagination Style Css --------*/

.pagination-area {
    padding: 82px 0 0;
    @media #{$md-device} {
        padding: 62px 0 0;
    }
    nav {
        .page-numbers {
            align-items: center;
            background-color: $white;
            border-radius: 5px;
            display: inline-flex;
            margin-bottom: 0;
            li {
                line-height: 1;
                margin-right: 36.57px;
                padding: 0;
                .page-number {
                    color: $black-999;
                    display: inline-block;
                    font-size: 18px;
                    font-weight: $font-weight-normal;
                    letter-spacing: 0;
                    line-height: 1;
                    &:hover {
                        color: $black;
                    }
                    &.current {
                        color: $black;
                        font-weight: $font-weight-bold;
                    }
                    &.prev {
                        border-radius: 0;
                        color: $black;
                        font-size: 14px;
                    }
                    &.next {
                        color: $black;
                        font-size: 22px;
                        &:hover {
                            color: $black;
                        }
                    }
                }
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                    margin-right: 0;
                }
            }
            &.page-numbers-style {
                box-shadow: 0px 10px 50px 0px rgba(171, 181, 189, 0.35);
            }
        }
    }
}

/*-------- Blockquote Style Css --------*/

.blockquote-area {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 165px 0 140px;
    @media #{$sm-device} {
        padding: 122px 0 94px;
    }

    p {
        color: $white;
        font-size: 36px;
        line-height: 1.2;
        margin-bottom: 70px;
        @media #{$sm-device} {
            font-size: 24px;
        }
    }
    cite {
        .name {
            color: $theme-color;
            display: block;
            font-size: 16px;
            font-weight: $font-weight-normal;
            font-style: normal;
            margin-bottom: 5px;
            text-transform: uppercase;
        }
        .job {
            color: #9192a4;
            font-size: 14px;
        }
    }
}

/*-------- Accordian Style Css --------*/

.accordian-content {
    .accordion {
        .accordion-item {
            border: none;
            margin-bottom: 30px;
            overflow: visible;
            .accordion-header {
                padding: 0;
                .accordion-button {
                    align-items: center;
                    background-color: $white;
                    border: none;
                    border-radius: 5px;
                    box-shadow: none;
                    color: $white;
                    display: flex;
                    font-size: 14px;

                    font-weight: $font-weight-bold;
                    justify-content: space-between;
                    padding: 15px 30px;
                    position: relative;
                    text-align: left;
                    width: 100%;
                    z-index: 1;
                    @include transition(0.4s);
                    &:before {
                        background-color: $theme-color;
                        border-radius: 5px;
                        content: "";
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        z-index: -1;
                        @include transition(0.4s);
                    }
                    &:after {
                        display: none;
                    }
                    .icon {
                        color: $white;
                        font-size: 14px;
                        @include transition(0.4s);
                        &.icon-plus {
                            display: none;
                        }
                    }
                    &.collapsed {
                        box-shadow: 0px 7px 30px 0px rgba(171, 181, 189, 0.35);
                        color: $black;
                        &:before {
                            background-color: $white;
                            background-image: none;
                        }
                        .icon {
                            color: $black;
                            &.icon-plus {
                                display: inline-block;
                            }
                            &.icon-minus {
                                display: none;
                            }
                        }
                        &:hover {
                            background-color: $white-f9;
                            &:before {
                                background-color: $white-f9;
                            }
                        }
                    }
                }
            }
            .accordion-body {
                line-height: 30px;
                padding: 34px 30px 16px;
            }
        }
        &.no-bg {
            .accordion-item {
                margin-bottom: 14px;
                .accordion-header {
                    .accordion-button {
                        background-color: transparent;
                        border-bottom: 1px solid #e1e1e1;
                        border-radius: 0;
                        color: $black;
                        padding: 0 0 20px 20px;
                        &:before {
                            display: none;
                        }
                        .icon {
                            color: $black;
                        }
                        &.collapsed {
                            box-shadow: none;
                            &:hover {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
            .accordion-collapse {
                border: none;
            }
            .accordion-body {
                padding: 20px 35px 14px 20px;
            }
        }
        &.accordion-style2 {
            .accordion-item {
                .accordion-header {
                    position: relative;
                    .icon {
                        font-size: 24px;
                        color: $black;
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        &.opened {
                            display: none;
                        }
                    }
                    &.active {
                        & .title {
                            border: none;
                            color: $theme-color;
                            font-size: 18px;
                            @media (max-width: 767px) {
                                font-size: 14px;
                            }
                            font-weight: $font-weight-bold;
                            justify-content: normal;
                            line-height: 24px;
                            padding: 0 30px 13px 0;
                            @include transition(0.3s);
                            @media #{$xxs-device} {
                                padding: 0 10px 13px 0;
                            }
                        }
                        .closed {
                            display: none;
                        }
                        .opened {
                            display: block !important;
                        }
                    }
                    .title {
                        display: flex;
                        align-items: center;
                        text-align: left;
                        font-size: 18px;
                        line-height: 24px;
                        @media (max-width: 767px) {
                            font-size: 14px;
                        }
                        color: $black;
                        font-weight: $font-weight-bold;
                        padding: 0 30px 10px 0;
                        @media #{$xxs-device} {
                            padding: 0 10px 10px 0;
                        }
                    }
                }
                .accordion-content {
                    color: $black-999;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 24px;
                    padding: 0px 30px 9px 16px;
                    display: none;
                    @media #{$xxs-device} {
                        padding: 0 12px 9px 16px;
                    }
                }
            }
        }
    }
}

/*-------- Tab Style Css --------*/

.feature-tab-content {
    .nav {
        &.nav-tabs {
            border-bottom: none;
            margin-bottom: 41px;
            .nav-item {
                margin-right: 60px;
                @media #{$sm-device} {
                    margin-right: 25px;
                }
                @media #{$xs-device} {
                    margin-right: 15px;
                    font-size: 12px;
                }
                .nav-link {
                    border: none;
                    border-bottom: 3px solid $gray-light;
                    color: $black;
                    padding: 0 0 13px;
                    text-transform: uppercase;
                    @include transition(0.3s);
                    &:hover {
                        color: $theme-color;
                    }
                    &:focus {
                        color: $theme-color;
                    }
                    &.active {
                        background-color: transparent;
                        border-bottom-color: $theme-color;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .tab-content {
        .tab-pane {
            p {
                line-height: 30px;
                margin-bottom: 10px;
            }
        }
    }
}

/*-------- Faq Style Css --------*/
.faq-area {
    .accordian-content {
        .accordion {
            .accordion-item {
                border: 1px solid #eaeaea;
                border-radius: 3px;
                margin-bottom: 15px;
                overflow: visible;
                .accordion-header {
                    position: relative;
                    padding: 0;
                    &.active {
                        .accordion-title {
                            color: $theme-color;
                        }
                        &:before {
                            content: "";
                            background-color: $theme-color;
                            background-image: none;
                            border-radius: 3px 3px 0 0;
                            height: 4px;
                            top: -1px;
                            z-index: 1;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            transition: all 0.4s;
                        }
                        .opened {
                            display: block !important;
                        }
                        .closed {
                            display: none;
                        }
                    }
                    .accordion-title {
                        display: block;
                        background-color: $white;
                        box-shadow: none;
                        color: $black;
                        transition: all 0.3s ease-in-out;
                        &:hover {
                            color: $theme-color;
                        }
                        font-size: 18px;
                        font-weight: $font-weight-bold;
                        padding: 13px 30px 14px;

                        .icon {
                            color: $theme-color;
                            font-size: 18px;
                            &:hover {
                                color: $theme-color;
                            }
                        }
                        &.collapsed {
                            color: $black;
                            padding: 14px 30px;
                            &:before {
                                background-color: transparent;
                                background-image: none;
                            }
                            .icon {
                                color: #3e4140;
                            }
                            &:hover {
                                background-color: transparent;
                                color: $theme-color;
                                &:before {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                    .icon {
                        font-size: 18px;
                        color: #000;
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        &.opened {
                            display: none;
                        }
                    }
                }
                .accordion-content {
                    letter-spacing: 0;
                    line-height: 24px;
                    padding: 4px 28px 23px 30px;
                    display: none;
                }
            }
        }
    }
}

/*-------- Page-Not-Found Style Css --------*/

.page-not-found-area {
    align-items: center;
    display: flex;
    height: 100vh;
    margin-top: 0;
    padding: 0;
    .section-title {
        margin-top: 35px;
        margin-bottom: 45px;
        @media #{$lg-device} {
            padding-top: 65px;
        }
        @media #{$md-device} {
            padding-top: 50px;
        }
        @media #{$sm-device} {
            padding-top: 40px;
        }
        @media #{$xs-device} {
            margin-bottom: 35px;
        }
        .title {
            color: $white;
            font-size: 60px;
            letter-spacing: 0;
            margin-bottom: 18px;
            text-transform: uppercase;
            @media #{$md-device} {
                font-size: 48px;
            }
            @media #{$xs-device} {
                font-size: 32px;
            }
        }
        .desc {
            margin-bottom: 0;
            p {
                color: $white;
                font-weight: $font-weight-light;
                font-size: 18px;
                line-height: 30px;
                @media #{$xs-device} {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }
    .btn-theme {
        padding: 10px 68px;
        @media #{$sm-device} {
            min-height: auto;
            padding: 8px 40px;
        }
        @media #{$xs-device} {
            padding: 5px 30px;
            font-size: 13px;
        }
    }
    a {
        text-transform: capitalize;
        &:after {
            width: 100%;
        }
    }
    .widget-search-box {
        &.search-style2 {
            .btn-src {
                border-left: none;
            }
        }
    }
}

/*-------- Coming-Soon Style Css --------*/

.coming-soon-area {
    background-position: top right;
    background-repeat: no-repeat;
    align-items: center;
    display: flex;
    height: 100vh;
    padding: 0;
    .countdown-content {
        margin-bottom: 78px;
        @media #{$xlm-device} {
            margin-bottom: 35px;
        }
        .countdown-timer {
            li {
                margin: 0 55px;
                @media #{$lg-device} {
                    margin: 0 25px;
                }
                @media #{$xxs-device} {
                    margin: 0 15px;
                }
                span {
                    color: $white;
                    display: inline-block;
                    font-size: 48px;
                    font-weight: $font-weight-bold;
                    margin-bottom: 18px;
                    @media #{$xlm-device} {
                        font-size: 36px;
                        margin-bottom: 15px;
                    }
                    @media #{$xxs-device} {
                        font-size: 16px;
                    }
                }
                p {
                    color: $white;
                    font-size: 18px;
                    line-height: 30px;
                    margin-bottom: 0;
                    @media #{$xlm-device} {
                        font-size: 16px;
                    }
                    @media #{$xxs-device} {
                        font-size: 13px;
                    }
                }
            }
        }
    }
    .coming-soon-content {
        margin-top: 83px;
        .section-title {
            &.section-style7 {
                .separator-line {
                    @media #{$lg-device} {
                        display: none;
                    }
                }
            }
        }
        h2 {
            color: $white;
            font-size: 100px;
            font-weight: $font-weight-bold;
            line-height: 1;
            margin-bottom: 15px;
            margin-top: 0;
            @media #{$xlm-device} {
                font-size: 60px;
            }
            @media #{$md-device} {
                font-size: 48px;
            }
            @media #{$xxs-device} {
                font-size: 24px;
            }
        }
        p {
            color: $white;
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 64px;
            @media #{$xlm-device} {
                margin-bottom: 35px;
            }
            @media #{$md-device} {
                font-size: 14px;
                br {
                    display: none;
                }
            }
            @media #{$xxs-device} {
                font-size: 13px;
            }
        }
    }
    .input-btn-group {
        border-radius: 50px;
        display: flex;
        justify-content: space-between;
        height: 34px;
        padding: 1px;
        .form-control {
            border-radius: 50px;
            font-size: 14px;
            max-width: 85.3%;
            width: 100%;
            @media #{$lg-device} {
                max-width: 420px;
            }
            @media #{$md-device} {
                max-width: 320px;
            }
            @media #{$xxs-device} {
                max-width: 170px;
            }
        }
        .btn-theme {
            background-color: $white;
            border-color: $white;
            border-radius: 0 25px 25px 0;
            color: #c3c3c3;
            height: 32px;
            min-height: 32px;
            line-height: 31px;
            padding: 0 18px;
            &:hover {
                background-color: $black;
                border-color: $black;
            }
        }
    }
}

/*-------- Preloader Style Css --------*/

.preloader-deactive .preloader-wrap {
    display: none;
}

.preloader-wrap {
    background-color: $white;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99999;
    overflow: hidden;

    .preloader {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 50%;
        left: 50%;
        margin-left: -32px;
        margin-top: -50px;
        .dot {
            height: 100%;
            width: 100%;
            display: block;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
            -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
            -webkit-animation: la-rotateplane 1.2s infinite ease-in-out;
            animation: la-rotateplane 1.2s infinite ease-in-out;
            border-radius: 3px;
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            background-color: $theme-color;
        }
    }
}

@-webkit-keyframes la-rotateplane {
    0% {
        -webkit-transform: perspective(120px);
    }

    50% {
        -webkit-transform: perspective(120px) rotateY(180deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
}

@keyframes la-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

/*-------- Custom Font Style Css --------*/
.font-size-12 {
    font-size: 12px !important;
}
.font-size-14 {
    font-size: 14px !important;
}
.font-size-16 {
    font-size: 16px !important;
}
.font-size-24 {
    font-size: 24px !important;
}
.font-size-30 {
    font-size: 30px !important;
}
.font-size-36 {
    font-size: 36px !important;
}
.font-size-40 {
    font-size: 40px !important;
}
.font-size-44 {
    font-size: 44px !important;
}
.font-size-50 {
    font-size: 50px !important;
}

@media #{$md-device} {
    .font-size-sm-18 {
        font-size: 18px !important;
    }
    .font-size-sm-24 {
        font-size: 24px !important;
    }
}

.font-weight-300 {
    font-weight: $font-weight-light !important;
}

.font-weight-400 {
    font-weight: $font-weight-normal !important;
}

.font-weight-700 {
    font-weight: $font-weight-bold !important;
}

.font-weight-900 {
    font-weight: $font-weight-black !important;
}

.line-height-30 {
    line-height: 30px !important;
}

.letter-spacing-0 {
    letter-spacing: 0 !important;
}

.letter-spacing-1 {
    letter-spacing: 1px !important;
}

/*-------- Custom Color Style Css --------*/

.bgcolor-black {
    background-color: $black !important;
}

.bgcolor-black-light {
    background-color: #1d1f1f !important;
}

.bgcolor-white {
    background-color: $white !important;
}

.bgcolor-24 {
    background-color: #242424 !important;
}

.bgcolor-333 {
    background-color: #333333 !important;
}

.bgcolor-f5 {
    background-color: $white-f5 !important;
}

.bgcolor-f6 {
    background-color: $white-f6 !important;
}

.bgcolor-gray-light {
    background-color: #f4f6f8 !important;
}

.bgcolor-gray-lighter {
    background-color: $gray-lighter !important;
}

.bgcolor-gray {
    background-color: #f0f2f4 !important;
}

.bgcolor-gray-silver {
    background-color: #f0f1f3 !important;
}

.bgcolor-gray-ccc {
    background-color: $gray-ccc !important;
}

.bgcolor-theme {
    background-color: $theme-color !important;
}

.bgcolor-theme2 {
    background-color: $theme-color2 !important;
}

.bgcolor-theme3 {
    background-color: $theme-color3 !important;
}

.bgcolor-theme4 {
    background-color: $theme-color4 !important;
}

.text-theme-color {
    color: $theme-color !important;
}

.text-theme-color2 {
    color: $theme-color2 !important;
}

.text-theme-color3 {
    color: $theme-color3 !important;
}

.text-theme-color4 {
    color: $theme-color4 !important;
}

.text-theme-color5 {
    color: $theme-color5 !important;
}

.text-light {
    color: $white !important;
}

.text-dark {
    color: $black-0e !important;
}

.text-black {
    color: $black !important;
}

.text-ccc {
    color: $gray-ccc !important;
}

.text-hover-theme-color {
    &:hover {
        color: $theme-color !important;
    }
}

/*-------- Custom Margin Padding Style Css --------*/

.mt-10 {
    margin-top: 10px;
}

.mt-12 {
    margin-top: 12px !important;
}

.mt-15 {
    margin-top: 15px;
}

.mt-18 {
    margin-top: 18px !important;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-55 {
    margin-top: 55px;
}

.mt-60 {
    margin-top: 60px !important;
}

.mt-65 {
    margin-top: 65px;
}

.mt-80 {
    margin-top: 80px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mt-90 {
    margin-top: 90px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

.mt-120 {
    margin-top: 120px !important;
}

@media #{$lg-device} {
    .mt-lg-50 {
        margin-top: 50px !important;
    }
    .mt-lg-70 {
        margin-top: 70px !important;
    }
}

@media #{$md-device} {
    .md-mt-0 {
        margin-top: 0 !important;
    }
    .mt-md-30 {
        margin-top: 30px !important;
    }
    .mt-md-40 {
        margin-top: 40px !important;
    }
    .mt-md-50 {
        margin-top: 50px !important;
    }
    .mt-md-60 {
        margin-top: 60px !important;
    }
    .mt-md-70 {
        margin-top: 70px !important;
    }
}

@media #{$sm-device} {
    .sm-mt-0 {
        margin-top: 0 !important;
    }
    .mt-sm-30 {
        margin-top: 30px !important;
    }
}

@media #{$xs-device} {
    .mt-xs-0 {
        margin-top: 0px;
    }
    .mt-xs-30 {
        margin-top: 30px !important;
    }
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-25 {
    margin-right: 25px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-80 {
    margin-right: 80px;
}

.mr--100 {
    margin-right: -100px;
}

@media #{$md-device} {
    .md-mr-0 {
        margin-right: 0 !important;
    }
}

@media #{$xs-device} {
    .mr-xs-0 {
        margin-right: 0;
    }
    .mr-xs-15 {
        margin-right: 15;
    }
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-45 {
    margin-bottom: 45px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-55 {
    margin-bottom: 55px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-65 {
    margin-bottom: 65px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.mb-85 {
    margin-bottom: 85px !important;
}

.mb-90 {
    margin-bottom: 90px !important;
}

.mb-95 {
    margin-bottom: 95px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

.mb-115 {
    margin-bottom: 115px !important;
}

.mb-120 {
    margin-bottom: 120px !important;
}

.mb-150 {
    margin-bottom: 150px !important;
}

.mb-438 {
    margin-bottom: 438px !important;
}

.mb-470 {
    margin-bottom: 470px !important;
}

.mb-471 {
    margin-bottom: 471px !important;
}

@media #{$lg-device} {
    .lg-mb-0 {
        margin-bottom: 0 !important;
    }
    .mb-lg-30 {
        margin-bottom: 30px !important;
    }
    .mb-lg-40 {
        margin-bottom: 40px !important;
    }
    .mb-lg-50 {
        margin-bottom: 50px !important;
    }
    .mb-lg-70 {
        margin-bottom: 70px !important;
    }
    .mb-lg-80 {
        margin-bottom: 80px !important;
    }
    .mb-lg-448 {
        margin-bottom: 448px !important;
    }
}

@media #{$md-device} {
    .md-mb-0,
    .md-mb-0 {
        margin-bottom: 0px !important;
    }
    .mb-md-0 {
        margin-bottom: 0px !important;
    }
    .mb-md-10 {
        margin-bottom: 10px !important;
    }
    .mb-md-30 {
        margin-bottom: 30px !important;
    }
    .mb-md-40 {
        margin-bottom: 40px !important;
    }
    .mb-md-50 {
        margin-bottom: 50px !important;
    }
    .mb-md-60 {
        margin-bottom: 60px !important;
    }
    .mb-md-70 {
        margin-bottom: 70px !important;
    }
    .mb-md-80 {
        margin-bottom: 80px !important;
    }
    .mb-md-90 {
        margin-bottom: 90px !important;
    }
}

@media #{$sm-device} {
    .mb-sm-0,
    .sm-mb-0 {
        margin-bottom: 0px !important;
    }
    .mb-sm-10 {
        margin-bottom: 10px !important;
    }
    .mb-sm-20 {
        margin-bottom: 20px !important;
    }
    .mb-sm-30 {
        margin-bottom: 30px !important;
    }
    .mb-sm-40 {
        margin-bottom: 40px !important;
    }
    .mb-sm-50 {
        margin-bottom: 50px !important;
    }
    .mb-sm-60 {
        margin-bottom: 60px !important;
    }
    .mb-sm-70 {
        margin-bottom: 70px !important;
    }
}

@media #{$xs-device} {
    .mb-xs-0 {
        margin-bottom: 0 !important;
    }
    .mb-xs-30 {
        margin-bottom: 30px !important;
    }
    .mb-xs-50 {
        margin-bottom: 50px !important;
    }
}

@media #{$xxs-device} {
    .mb-xxs-0 {
        margin-bottom: 0 !important;
    }
    .mb-xxs-30 {
        margin-bottom: 30px !important;
    }
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-25 {
    margin-left: 25px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-40 {
    margin-left: 40px;
}

.ml-50 {
    margin-left: 50px !important;
}

@media #{$md-device} {
    .ml-md-0 {
        margin-left: 0 !important;
    }
    .md-ml-0 {
        margin-left: 0 !important;
    }
}

@media #{$xxs-device} {
    .ml-xxs-5 {
        margin-left: 5px !important;
    }
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-35 {
    padding-bottom: 35px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pb-55 {
    padding-bottom: 55px !important;
}

.pb-60 {
    padding-bottom: 60px !important;
}

.pb-70 {
    padding-bottom: 70px !important;
}

.pb-75 {
    padding-bottom: 75px !important;
}

.pb-80 {
    padding-bottom: 80px !important;
}

.pb-85 {
    padding-bottom: 85px !important;
}

.pb-90 {
    padding-bottom: 90px !important;
}

.pb-95 {
    padding-bottom: 95px !important;
}

.pb-100 {
    padding-bottom: 100px !important;
}

.pb-110 {
    padding-bottom: 110px !important;
}

.pb-115 {
    padding-bottom: 115px !important;
}

.pb-120 {
    padding-bottom: 120px !important;
}

.pb-125 {
    padding-bottom: 125px !important;
}

.pb-130 {
    padding-bottom: 130px;
}

.pb-140 {
    padding-bottom: 140px;
}

.pb-150 {
    padding-bottom: 150px;
}

.pb-160 {
    padding-bottom: 160px;
}

.pb-170 {
    padding-bottom: 170px;
}

@media #{$lg-device} {
    .pb-lg-10 {
        padding-bottom: 10px !important;
    }
    .pb-lg-30 {
        padding-bottom: 30px !important;
    }
    .pb-lg-70 {
        padding-bottom: 70px !important;
    }
    .pb-lg-90 {
        padding-bottom: 90px !important;
    }
}

@media #{$md-device} {
    .md-pb-0 {
        padding-bottom: 0 !important;
    }
    .pb-md-10 {
        padding-bottom: 10px !important;
    }
    .pb-md-20 {
        padding-bottom: 20px !important;
    }
    .pb-md-30 {
        padding-bottom: 30px !important;
    }
    .pb-md-40 {
        padding-bottom: 40px !important;
    }
    .pb-md-50 {
        padding-bottom: 50px !important;
    }
    .pb-md-60 {
        padding-bottom: 60px !important;
    }
    .pb-md-70 {
        padding-bottom: 70px !important;
    }
    .pb-md-80 {
        padding-bottom: 80px !important;
    }
    .pb-md-90 {
        padding-bottom: 90px !important;
    }
    .pb-md-100 {
        padding-bottom: 100px !important;
    }
    .pb-md-120 {
        padding-bottom: 120px !important;
    }
    .pb-md-170 {
        padding-bottom: 170px !important;
    }
}

@media #{$sm-device} {
    .sm-pb-0 {
        padding-bottom: 0 !important;
    }
    .pb-sm-20 {
        padding-bottom: 20px !important;
    }
    .pb-sm-30 {
        padding-bottom: 30px !important;
    }
    .pb-sm-50 {
        padding-bottom: 50px !important;
    }
    .pb-sm-60 {
        padding-bottom: 60px !important;
    }
    .pb-sm-70 {
        padding-bottom: 70px !important;
    }
    .pb-sm-80 {
        padding-bottom: 80px !important;
    }
    .pb-sm-100 {
        padding-bottom: 100px !important;
    }
    .pb-sm-170 {
        padding-bottom: 170px !important;
    }
}

@media #{$xs-device} {
    .pb-xs-80 {
        padding-bottom: 80px !important;
    }
}

.pl-10 {
    padding-left: 10px;
}

.pl-15 {
    padding-left: 15px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-30 {
    padding-left: 30px;
}

.pl-40 {
    padding-left: 40px;
}

.pl-50 {
    padding-left: 50px;
}

.pl-100 {
    padding-left: 100px;
}

.pl-130 {
    padding-left: 130px !important;
}

@media #{$lg-device} {
    .pl-lg-15 {
        padding-left: 15px !important;
    }
}

@media #{$md-device} {
    .md-pl-0 {
        padding-left: 0 !important;
    }
    .md-pl-15 {
        padding-left: 15px !important;
    }
}

@media #{$sm-device} {
    .sm-pl-0 {
        padding-left: 0 !important;
    }
    .sm-pl-15,
    .pl-sm-15 {
        padding-left: 15px !important;
    }
}

@media #{$xxs-device} {
    .pl-xxs-0 {
        padding-left: 0 !important;
    }
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-35 {
    padding-top: 35px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-55 {
    padding-top: 55px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.pt-70 {
    padding-top: 70px !important;
}

.pt-80 {
    padding-top: 80px !important;
}

.pt-90 {
    padding-top: 90px !important;
}

.pt-95 {
    padding-top: 95px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

.pt-110 {
    padding-top: 110px !important;
}

.pt-120 {
    padding-top: 120px !important;
}

.pt-125 {
    padding-top: 125px !important;
}

.pt-130 {
    padding-top: 130px !important;
}

.pt-140 {
    padding-top: 140px !important;
}

.pt-150 {
    padding-top: 150px !important;
}

.pt-160 {
    padding-top: 160px !important;
}

.pt-165 {
    padding-top: 165px;
}

.pt-170 {
    padding-top: 170px;
}

@media #{$lg-device} {
    .lg-pt-0 {
        padding-top: 0 !important;
    }
}

@media #{$md-device} {
    .pt-md-10 {
        padding-top: 10px !important;
    }
    .pt-md-30 {
        padding-top: 30px !important;
    }
    .pt-md-40 {
        padding-top: 40px !important;
    }
    .pt-md-50 {
        padding-top: 50px !important;
    }
    .pt-md-60 {
        padding-top: 60px !important;
    }
    .pt-md-65 {
        padding-top: 65px !important;
    }
    .pt-md-70 {
        padding-top: 70px !important;
    }
    .pt-md-80 {
        padding-top: 80px !important;
    }
    .pt-md-90 {
        padding-top: 90px !important;
    }
    .pt-md-100 {
        padding-top: 100px !important;
    }
    .pt-md-120 {
        padding-top: 120px !important;
    }
}

@media #{$sm-device} {
    .pt-sm-10 {
        padding-top: 10px !important;
    }
    .pt-sm-30 {
        padding-top: 30px !important;
    }
    .pt-sm-50 {
        padding-top: 50px !important;
    }
    .pt-sm-60 {
        padding-top: 60px !important;
    }
    .pt-sm-70 {
        padding-top: 70px !important;
    }
    .pt-sm-80 {
        padding-top: 80px !important;
    }
    .pt-sm-90 {
        padding-top: 90px !important;
    }
    .pt-sm-100 {
        padding-top: 100px !important;
    }
}

@media #{$xs-device} {
    .pt-xs-40 {
        padding-top: 40px !important;
    }
}

.pr-15 {
    padding-right: 15px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-30 {
    padding-right: 30px;
}

.pr-40 {
    padding-right: 40px;
}

.pr-50 {
    padding-right: 50px;
}

.pr-60 {
    padding-right: 60px;
}

.pr-70 {
    padding-right: 70px;
}

.pr-80 {
    padding-right: 80px;
}

.pr-90 {
    padding-right: 90px;
}

.pr-100 {
    padding-right: 100px;
}

@media #{$lg-device} {
    .lg-pr-0 {
        padding-right: 0 !important;
    }
    .pr-lg-15 {
        padding-right: 15px !important;
    }
}

@media #{$sm-device} {
    .sm-pr-0 {
        padding-right: 0 !important;
    }
    .sm-pr-15,
    .pr-sm-15 {
        padding-right: 15px !important;
    }
}

@media #{$md-device} {
    .md-pr-15 {
        padding-right: 15px;
    }
    .md-pl-15 {
        padding-left: 15px;
    }
}

.d-contents {
    display: contents;
}

/*-------- Scroll To Top Style Css --------*/

.scroll-to-top {
    position: fixed;
    right: 30px;
    bottom: -60px;
    z-index: 999;
    box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
    display: block;
    padding: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    line-height: 60px;
    border: 0;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    background-color: black;
    background-size: 200% auto;
    background-position: left center;
    color: $white;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    @media #{$xxs-device} {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
    }

    &.show {
        visibility: visible;
        opacity: 1;
        bottom: 60px;
    }

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        color: $white;
        transition: all 0.3s ease-in-out;
    }

    .arrow-top {
        transform: translate(-50%, -50%);
    }

    .arrow-bottom {
        transform: translate(-50%, 80px);
    }

    &:hover {
        background-position: right center;
        .arrow-top {
            transform: translate(-50%, -80px);
        }
        .arrow-bottom {
            transform: translate(-50%, -50%);
        }
    }
}

.mb-n4 {
    margin-bottom: -1.5rem;
}

.section-py {
    padding: 45px 0px 80px;
}

@media (max-width: 767px) {
    .section-py {
        padding: 0px 0px 80px;
    }
}

@media (min-width: 768px) {
    .section-py {
        padding: 45px 0px 90px;
    }
}

@media (min-width: 1200px) {
    .section-py {
        padding: 45px 0px 125px;
    }
}
