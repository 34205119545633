/******************************** 
 offcanvas menu style
 ********************************/
body {
    &.offcanvas-open {
        overflow-y: hidden;
        padding-right: 17px;
        @include max-screen(991) {
            padding-right: 0px;
        }
    }
}

.offcanvas-overlay {
    &.active {
        display: block;
    }
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background-color: $overlay-color;
}

// offcanvas
.offcanvas {
    &.offcanvas-open {
        @include transform(translateX(0));
    }
    &.offcanvas-mobile-menu {
        right: auto;
        left: 0;
        transform: translateX(-100%);
        &.offcanvas-open {
            transform: translateX(0);
        }
    }

    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: auto;
    display: block;
    overflow: hidden;
    width: 400px;
    @include max-screen(480) {
        width: 300px;
    }
    height: 100vh;
    @include transform(translateX(100%));
    background-color: $color-white;
    box-shadow: none;
    @include transition(0.5s ease-in-out);

    .inner {
        padding: 20px;
        @include max-screen(991) {
            overflow-y: scroll;
            height: 660px;
        }
        @include max-screen(767) {
            height: calc(100% - 30px);
        }

        @include max-screen(480) {
            padding: 10px;
        }
    }

    .title {
        color: $color-dark;
        font-weight: 700;
    }
    .offcanvas-close {
        position: relative;
        width: 20px;
        height: 20px;
        text-indent: -9999px;
        border: 0;
        background-color: transparent;
        &::after {
            position: absolute;
            top: calc(50% - 1px);
            left: 0;
            width: 20px;
            height: 2px;
            content: "";
            @include transition(0.5s ease-in-out);
            @include transform(rotate(-45deg));
            background-color: $color-dark;
        }
        &:hover:after {
            @include transform(rotate(0deg));
        }
        &::before {
            position: absolute;
            top: calc(50% - 1px);
            left: 0;
            width: 20px;
            height: 2px;
            content: "";
            @include transition(0.5s ease-in-out);
            @include transform(rotate(45deg));
            background-color: $color-dark;
        }
        &:hover:before {
            @include transform(rotate(180deg));
        }
    }

    .offcanvas-menu ul {
        li {
            position: relative;
            display: block;
            line-height: 28px;
            a {
                display: block;
                position: relative;
                padding: 8px 20px;
                text-transform: capitalize;
                border-top: 1px solid $border-color2;
                font-size: 14px;
                line-height: 30px;
                color: $color-dark;
                &:hover {
                    color: var(--primary-color);
                }
            }
            .offcanvas-submenu {
                position: static;
                top: auto;
                display: none;
                visibility: visible;
                width: 100%;
                min-width: auto;
                transition: none;
                opacity: 1;
                box-shadow: none;
                li {
                    line-height: inherit;
                    position: relative;
                    a {
                        font-weight: 400;
                        padding-left: 40px;
                    }
                    ul a {
                        padding-left: 60px;
                    }
                }
            }

            .menu-expand {
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                width: 24px;
                height: 44px;
                cursor: pointer;
                background-color: transparent;
                &::before,
                &::after {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 14px;
                    height: 2px;
                    content: "";
                    @include transition(0.5s ease-in-out);
                    background-color: $color-dark;
                    &:hover {
                        background-color: $theme-color;
                    }
                }

                &::after {
                    @include transform(rotate(90deg));
                }
            }
            &.active > .menu-expand {
                &::after {
                    @include transform(rotate(0) scale(0.75));
                }
            }
        }
    }

    .offcanvas-social {
        li {
            display: inline-block;
            margin: 0 10px 10px 0;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                line-height: 40px;
                width: 40px;
                height: 40px;
                border: 2px solid transparent;
                @include border-radius(50%);
            }
        }
    }
}

// offcanvas-menu
// .offcanvas-menu {
//   li > {
//     :hover{
//       .menu-expand{
//         &::after{
//           background: $theme-color;
//         }
//         &::before{
//           background: $theme-color;
//         }
//       }
//     }
//   }
// }

// offcanvas-social

.offcanvas-social {
    a {
        color: $color-white;
        background: var(--primary-color);
        &:hover {
            background: transparent;
            color: var(--primary-color);
            border-color: var(--primary-color) !important;
        }
    }
}

/******************************** 
 offcanvas menu style ENd
 ********************************/
